export async function newsletterSubscribe(bridgeUrl, emailAdress) {
  const init = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    body: JSON.stringify({ email: emailAdress }),
  }

  const response = await fetch(`${bridgeUrl}/newsletter/subscribe`, init)
  const result = await response.json()

  return result.success ? result.success : false
}
