import { render, staticRenderFns } from "./MatrixOverviewSectionEntries.vue?vue&type=template&id=338c37f2"
import script from "./MatrixOverviewSectionEntries.vue?vue&type=script&lang=js"
export * from "./MatrixOverviewSectionEntries.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculeCardCover: require('/home/userapp/components/Molecule/Card/CardCover.vue').default,MoleculeCardOverview: require('/home/userapp/components/Molecule/Card/CardOverview.vue').default})
